<template>
  <div>
    <div>
      <v-row>
        <v-col class="d-flex align-center justify-start">
          <v-btn-toggle
            :value="mode"
            mandatory
            color="secondary"
            dense
            group
            @change="(mode) => $emit('mode-change', mode)"
          >
            <v-btn value="day">
              {{ $t('messages.views.admin.components.dashboard.sectionModal.window.stats.mode.day') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-col class="d-flex align-center justify-center">
          <span class="mx-5">
            <o-select
              v-model="day"
              :items="days"
              item-text="name"
              item-value="number"
              dense
              prepend-icon="mdi-calendar-today"
            />
          </span>
        </v-col>

        <v-col class="d-flex align-center justify-end">
          <v-btn-toggle
            v-model="dataType"
            color="secondary"
            dense
            group
          >
            <v-btn value="speed">
              <v-icon
                size="20"
                class="mr-1"
              >
                mdi-speedometer
              </v-icon>
              {{ $t('messages.views.admin.components.dashboard.sectionModal.window.stats.speed') }}
            </v-btn>
            <v-btn value="jamFactor">
              <v-icon
                size="20"
                class="mr-1"
              >
                mdi-car-multiple
              </v-icon>
              {{ $t('messages.views.admin.components.dashboard.sectionModal.window.stats.jam') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </div>

    <div v-if="dataType === 'speed'">
      <v-row>
        <v-col>
          <o-card-value
            class="elevation-0 rounded-lg"
            outlined
            :title="$t('labels.section.averageSpeed')"
            :loading="loading"
            :value-color="speedColor"
            :value="`${getChartDataAverageValue(stats.averageSpeed)} km/h`"
            :has-data="hasData(stats.averageSpeed)"
          />
        </v-col>
        <v-col>
          <o-card-value
            class="elevation-0 rounded-lg"
            outlined
            :title="$t('labels.section.averageSpeedUncut')"
            :loading="loading"
            :value-color="speedColor"
            :value="`${getChartDataAverageValue(stats.averageSpeedUncut)} km/h`"
            :has-data="hasData(stats.averageSpeedUncut)"
          />
        </v-col>
        <v-col>
          <o-card-value
            class="elevation-0 rounded-lg"
            outlined
            :title="$t('labels.section.freeFlowSpeed')"
            :loading="loading"
            :value-color="speedColor"
            :value="`${getChartDataAverageValue(stats.freeFlowSpeed)} km/h`"
            :has-data="hasData(stats.freeFlowSpeed)"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="6"
        >
          <o-card-stat
            class="elevation-0 rounded-lg fill-height stats-card"
            outlined
            :title="$t('messages.views.admin.components.dashboard.sectionModal.window.stats.speedEvolution')"
            :loading="loading"
            :has-data="hasData(stats.averageSpeed)"
            @download-chart-as-csv="() => downloadChartAsCsv('speedEvolution')"
            @download-chart-as-png="() => downloadChartAsPng('speedEvolution')"
          >
            <template
              v-slot:default="{ expanded }"
              class="fill-height fill-width"
            >
              <o-chart-speed-evolution
                ref="speedEvolutionChart"
                class="fill-height fill-width"
                :labels="chartLabels"
                :data="stats.averageSpeed"
                :free-flow-speed="getChartDataAverageValue(stats.freeFlowSpeed)"
                :height="expanded ? 1000 : chartHeight"
              />
            </template>
          </o-card-stat>
        </v-col>

        <v-col
          cols="6"
        >
          <o-card-stat
            class="elevation-0 rounded-lg fill-height stats-card"
            outlined
            :title="$t('messages.views.admin.components.dashboard.sectionModal.window.stats.speedDistribution')"
            :loading="loading"
            :has-data="hasData(stats.averageSpeed)"
            @download-chart-as-csv="() => downloadChartAsCsv('speedDistribution')"
            @download-chart-as-png="() => downloadChartAsPng('speedDistribution')"
          >
            <template
              v-slot:default="{ expanded }"
              class="fill-height fill-width"
            >
              <o-chart-speed-distribution
                ref="speedDistributionChart"
                class="fill-height fill-width"
                :data="stats.averageSpeed"
                :height="expanded ? 1000 : chartHeight"
                :slices="speedDistributionSlices"
              />
            </template>
          </o-card-stat>
        </v-col>
      </v-row>
    </div>

    <div v-if="dataType === 'jamFactor'">
      <v-row>
        <v-col>
          <o-card-value
            class="d-flex flex-column elevation-0 rounded-lg"
            outlined
            :title="$t('messages.views.admin.components.dashboard.sectionModal.window.stats.jamType')"
            :loading="loading"
            :value-color="jamColor"
            :value="$t('constants.section.jamFactor.' + averageJamType)"
            :has-data="hasData(stats.jamFactor)"
          />
        </v-col>
        <v-col>
          <o-card-value
            class="d-flex flex-column elevation-0 rounded-lg"
            outlined
            :title="$t('messages.views.admin.components.dashboard.sectionModal.window.stats.jamTime')"
            :loading="loading"
            :value-color="jamColor"
            :value="jamTime"
            :has-data="hasData(stats.jamFactor)"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="6"
        >
          <o-card-stat
            class="fill-height stats-card elevation-0 rounded-lg"
            outlined
            :title="$t('messages.views.admin.components.dashboard.sectionModal.window.stats.jamFactorEvolution')"
            :loading="loading"
            :has-data="hasData(stats.jamFactor)"
            @download-chart-as-csv="() => downloadChartAsCsv('jamFactorEvolution')"
            @download-chart-as-png="() => downloadChartAsPng('jamFactorEvolution')"
          >
            <template
              v-slot:default="{ expanded }"
              class="fill-height fill-width"
            >
              <o-chart-jam-factor-evolution
                ref="jamFactorEvolutionChart"
                class="fill-height fill-width"
                :labels="chartLabels"
                :data="stats.jamFactor"
                :height="expanded ? 1000 : chartHeight"
              />
            </template>
          </o-card-stat>
        </v-col>

        <v-col
          cols="6"
        >
          <o-card-stat
            class="fill-height stats-card elevation-0 rounded-lg"
            outlined
            :title="$t('messages.views.admin.components.dashboard.sectionModal.window.stats.jamFactorDistribution')"
            :loading="loading"
            :has-data="hasData(stats.jamFactor)"
            @download-chart-as-csv="() => downloadChartAsCsv('jamFactorDistribution')"
            @download-chart-as-png="() => downloadChartAsPng('jamFactorDistribution')"
          >
            <template
              v-slot:default="{ expanded }"
              class="fill-height fill-width"
            >
              <o-chart-jam-factor-distribution
                ref="jamFactorDistributionChart"
                class="fill-height fill-width"
                :data="stats.jamFactor"
                :labels="[]"
                :height="expanded ? 1000 : chartHeight"
              />
            </template>
          </o-card-stat>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import { getJamFactorTextColorFromValue, getJamFactorType, getSpeedColor } from '@utils/section';
  import { downloadCanvasAsPng, downloadDataAsCsv } from '@/utils/download';
  const moment = require('moment-timezone');

  export default {
    props: {
      value: {
        type: [Number, String],
        required: true,
      },
      date: {
        type: Date,
        default: null,
      },
    },

    apollo: {
      data: {
        query: require('@gql/views/admin/components/dashboard/section-modal/window/stats/statsByDay.gql'),
        variables: function () {
          return {
            timezone: moment.tz.guess(),
            from: this.from.clone().utc().format('Y-MM-DD HH:mm:ss'),
            to: this.to.clone().utc().format('Y-MM-DD HH:mm:ss'),
            day: this.day,
            step: this.step,
            entityId: this.value,
          };
        },
        client: 'stats',
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        result (res, key) {
          this.loading = res.loading;
        },
        update: function (data) {
          this.stats.jamFactor = this.statsToChartData(this.times, data.jamFactor);
          this.stats.freeFlowSpeed = this.statsToChartData(this.times, data.freeFlowSpeed);
          this.stats.averageSpeed = this.statsToChartData(this.times, data.averageSpeed);
          this.stats.averageSpeedUncut = this.statsToChartData(this.times, data.averageSpeedUncut);
        },
      },
    },

    data: () => ({
      from: moment().subtract(1, 'year').set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      }),
      to: moment().add(1, 'day').set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      }),
      day: moment().day(),
      dataType: 'speed',
      speedDistributionSlices: 2,
      chartHeight: 280,
      mode: 'day',
      loading: true,
      stats: {
        jamFactor: null,
        freeFlowSpeed: null,
        averageSpeed: null,
        averageSpeedUncut: null,
      },
    }),

    computed: {
      days () {
        const days = [];
        for (let i = 1; i <= 7; i++) {
          days.push({
            name: this.$t('constants.days.' + i),
            number: i,
          });
        }

        return days;
      },

      step () {
        switch (this.mode) {
          case 'day':
            return 30;
          default:
            throw new Error('Invalid mode');
        }
      },

      times () {
        const times = [];
        const endingTimeStamp = 24 * 60 * 60;
        let currentTimestamp = 0;

        while (currentTimestamp < endingTimeStamp) {
          times.push(currentTimestamp);
          currentTimestamp += this.step * 60;
        }

        return times;
      },

      chartLabels () {
        return this.times.map((time) => {
          time = parseInt(time);
          const hours = Math.floor(time / (60 * 60));
          const minutes = Math.floor(time / 60) - hours * 60;

          return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
        });
      },

      jamColor () {
        return getJamFactorTextColorFromValue(this.getChartDataAverageValue(this.stats.jamFactor));
      },

      speedColor () {
        const averageSpeed = this.getChartDataAverageValue(this.stats.averageSpeed);
        const averageFreeFlowSpeed = this.getChartDataAverageValue(this.stats.freeFlowSpeed);
        return getSpeedColor(averageSpeed, averageFreeFlowSpeed);
      },

      averageJamType () {
        let total = 0;
        let average = 0;
        if (this.stats.jamFactor !== null) {
          for (const jamFactor of this.stats.jamFactor) {
            if (jamFactor !== null) {
              average += parseFloat(jamFactor);
              total++;
            }
          }
          average /= total;
        }

        return getJamFactorType(average);
      },

      jamTime () {
        let total = 0;
        if (this.stats.jamFactor !== null) {
          for (const jamFactor of this.stats.jamFactor) {
            if (jamFactor !== null && jamFactor >= 8) {
              total += this.step;
            }
          }
        }

        const hours = ((total - (total % 60)) / 60).toFixed(0);
        const minutes = (total % 60).toFixed(0);
        const arr = [];

        if (hours > 0) {
          arr.push(hours + 'h');
        }
        if (minutes > 0) {
          arr.push(minutes + 'min');
        }

        if (arr.length > 0) {
          return arr.join(' ');
        } else {
          return '0 min';
        }
      },
    },

    methods: {
      getJamFactorTextColorFromValue (value) {
        return getJamFactorTextColorFromValue(value);
      },

      getSpeedColor (stats) {
        return getSpeedColor(this.getChartDataAverageValue(stats), this.getChartDataAverageValue(this.stats.freeFlowSpeed));
      },

      downloadChartAsCsv (type) {
        const headers = [];
        switch (type) {
          case 'speedEvolution':
            headers.push(
              this.$t('messages.views.admin.components.dashboard.sectionModal.window.stats.csv.headers.time'),
              this.$t('messages.views.admin.components.dashboard.sectionModal.window.stats.csv.headers.averageSpeed')
            );
            break;
          case 'speedDistribution':
            headers.push(
              this.$t('messages.views.admin.components.dashboard.sectionModal.window.stats.csv.headers.from'),
              this.$t('messages.views.admin.components.dashboard.sectionModal.window.stats.csv.headers.to'),
              this.$t('messages.views.admin.components.dashboard.sectionModal.window.stats.csv.headers.percentage')
            );
            break;
          case 'jamFactorEvolution':
            headers.push(
              this.$t('messages.views.admin.components.dashboard.sectionModal.window.stats.csv.headers.time'),
              this.$t('messages.views.admin.components.dashboard.sectionModal.window.stats.csv.headers.jamFactor')
            );
            break;
          case 'jamFactorDistribution':
            headers.push(this.$t('messages.views.admin.components.dashboard.sectionModal.window.stats.csv.headers.jamFactorType'));
            headers.push(this.$t('messages.views.admin.components.dashboard.sectionModal.window.stats.csv.headers.percentage'));
            break;
        }

        const data = [];

        switch (type) {
          case 'speedEvolution':
            for (const i in this.chartLabels) {
              const values = [];
              values.push(this.stats.averageSpeed[i]);
              data.push([
                Array.isArray(this.chartLabels[i]) ? this.chartLabels[i].join(' - ') : this.chartLabels[i],
                ...values,
              ]);
            }
            break;
          case 'speedDistribution':
            const slices = [];
            let sum = 0;
            for (const speed of this.stats.averageSpeed) {
              if (speed !== null) {
                const index = Math.round(speed - (speed % this.speedDistributionSlices));
                if (slices[index] === undefined) {
                  slices[index] = 0;
                }
                slices[index]++;
                sum++;
              }
            }

            for (const index in slices) {
              data.push([
                index,
                (parseInt(index) + this.speedDistributionSlices - 1),
                (slices[index] / sum * 100).toFixed(2),
              ]);
            }
            break;
          case 'jamFactorEvolution':
            for (const i in this.chartLabels) {
              data.push([
                Array.isArray(this.chartLabels[i]) ? this.chartLabels[i].join(' - ') : this.chartLabels[i],
                (this.stats.jamFactor[i] * 10).toFixed(0),
              ]);
            }
            break;
          case 'jamFactorDistribution':
            const values = [];
            let total = 0;
            for (const jamFactor of this.stats.jamFactor) {
              if (jamFactor !== null) {
                const type = getJamFactorType(jamFactor);
                if (values[type] === undefined) {
                  values[type] = 0;
                }
                values[type]++;
                total++;
              }
            }

            for (const type in values) {
              data.push([
                this.$t('constants.section.jamFactor.' + type),
                Math.round(values[type] / total * 100),
              ]);
            }
            break;
        }

        downloadDataAsCsv('section_' + type, headers, data);
      },

      downloadChartAsPng (type) {
        const canvas = this.$refs[type + 'Chart'].$refs.canvas;
        downloadCanvasAsPng('section_' + type, canvas);
      },

      statsToChartData (times, stats) {
        const data = [];
        const statsTimestamps = stats.map(item => parseInt(item.time));
        for (const time of times) {
          const index = statsTimestamps.indexOf(time);
          data.push(index !== -1 ? stats[index].value : null);
        }

        return data;
      },

      getChartDataAverageValue (data) {
        if (!data) {
          return 0;
        }

        let average = 0;
        let total = 0;
        if (data.length) {
          data.forEach((value) => {
            if (value !== null) {
              average += parseFloat(value);
              total++;
            }
          });

          if (total) {
            average /= total;
          }
        }

        return Math.round(average);
      },

      hasData (values) {
        return this.loading || (values && !!values.filter(value => value !== null).length);
      },
    },
  };
</script>

<style scoped lang="sass">
  .stats-card
    height: 358px !important
</style>
